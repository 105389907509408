body{
  background-color: #fafafa;
  font-family: Lato, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Top-navbar{
  background-color: #fff;
  border-bottom: 1px solid #eee;
  height: 3rem;
  position: fixed;
  top: 0; 
  width: 100%; /* Full width */
  z-index: 100;
}

.Sidebar-wide{
  height: 100%;
  width: 18rem;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  padding-top: 2rem;
  padding-left: 3rem;
  margin-top: 3rem;
  text-align: left;
  font-weight: 700;;
}

.nav-link{
  color: #444 !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #0052cc !important;
  background-color: #fff !important;
}


/* ALL LOADERS */

.loader{
  width: 70;
  height: 10px;
  padding-top: 10px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

/* LOADER 4 */

#loader-4 span{
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #0000ff;
  margin: 0px 5px;
  opacity: 0;
}

#loader-4 span:nth-child(1){
  animation: opacitychange 1s ease-in-out infinite;
}

#loader-4 span:nth-child(2){
  animation: opacitychange 1s ease-in-out 0.33s infinite;
}

#loader-4 span:nth-child(3){
  animation: opacitychange 1s ease-in-out 0.66s infinite;
}

@keyframes opacitychange{
  0%, 100%{
    opacity: 0;
  }

  60%{
    opacity: 1;
  }
}

.navbar-light .navbar-nav .nav-link-red {
  color: #e74c3c!important;
}

.navbar-light .navbar-nav .nav-link-red:focus, .navbar-light .navbar-nav .nav-link-red:hover {
  color: #bd1200!important;
}

.bold-font{
  font-weight: 700;
}

.medium-font{
  font-weight: 700;
}

.btn-primary{
  background-color: #0052cc !important;
  border-color: #0052cc !important;;
}

.grey-font{
  color: #888;
}

.link-color{
  color: #0052cc;
}

@media only screen and (max-width: 600px) {
  .Sidebar-wide{
    display: none;
  }
}


.main-container-dashboard{
  text-align: left;
  margin-left: 24rem;
  margin-top: 6rem;
  padding-bottom: 3rem;
}


@media only screen and (max-width: 600px) {
  .main-container-dashboard{
    text-align: left;
    margin-top: 2rem !important;
    margin-left: 0  !important;
    padding-bottom: 2rem !important;
  }
}